import api from "../lib/axios";

export default {
  login(data) {
    return api.post('/login', data);
  },
  logout() {
    return api.post('/logout');
  },
  activateAccount(data) {
    return api.post('/activate-account', data);
  }
};