import { ref } from  'vue';
import { defineStore } from "pinia";

export const useNotificationStore = defineStore( 'notification', () => {

    const notification = ref({});

    function success(message) {
        notification.value.type = 'success';
        notification.value.message = message;
    }
    function error(message) {
        notification.value.type = 'danger';
        notification.value.message = message;
    }
    function clear() {
        notification.value.type = null;
        notification.value.message = null;
    }
    return {
        notification,
        success,
        error,
        clear
    }
});