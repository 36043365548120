import { createAcl, defineAclRules } from 'vue-simple-acl';
import { ref } from 'vue';

const user = ref(JSON.parse(localStorage.getItem('user')));

const rules = () => defineAclRules((setRule) => {
    setRule('is-admin', () => user.value.rol.includes('Administrador'));
    setRule('is-editor', () => user.value.permissions.includes('Editor'));
    setRule('is-writer', () => user.value.permissions.includes('Escritor'));
});

const simpleAcl = createAcl({
    user,
    rules
});

export default simpleAcl;
