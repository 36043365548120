import axios from "axios"

const api = axios.create({
    baseURL: process.env.VUE_APP_APIKEY,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
    }
})
api.interceptors.request.use(
    (config) => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            config.headers['Authorization'] = `Bearer ${user.token}`;
            config.headers['Role'] = user.rol;
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
export default api
