import store from "@/state/store";
import {useAuthStore} from "@/stores/auth";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      title: "Iniciar Sesión",
      beforeResolve(routeTo, routeFrom, next) {
        if (useAuthStore().loggedIn()) {
          next({
            name: "home"
          });
        } else {
          next();
        }
      }
    }
  },
  {
    path: "/activate-account",
    name: "activateAccount",
    component: () => import("../views/pages/account/activate-account"),
    meta: {
      title: "Activar Cuenta",
      beforeResolve(routeTo, routeFrom, next) {
        if (useAuthStore().loggedIn()) {
          next({ name: "home" });
        } else {
          next();
        }
      }
    }
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/pages/account/register"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (useAuthStore().loggedIn()) {
          // Redirect to the home page instead
          next({
            name: "home"
          });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/pages/account/forgot-password"),
    meta: {
      title: "Forgot password",
      beforeResolve(routeTo, routeFrom, next) {
        if (useAuthStore().loggedIn()) {
          next({ name: "home" });
        } else {
          next();
        }
      }
    }
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        //store.dispatch("authfack/logout");
        useAuthStore().logout()
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) => route.push("/login") );
        next( authRequiredOnPreviousRoute ? { name: "home" } : { ...routeFrom } );
      }
    }
  },
  {
    path: "/",
    name: "home",
    meta: { title: "Dashboard", authRequired: true },
    component: () => import("../views/pages/dashboard/index")
  },
  {
    path: "/utility/starter",
    name: "Starter-page",
    meta: { title: "Starter page", authRequired: true },
    component: () => import("../views/pages/utility/starter")
  },
  {
    path: "/utility/maintenance",
    name: "maintenance",
    meta: { title: "Maintenance", authRequired: true },
    component: () => import("../views/pages/utility/maintenance")
  },
  {
    path: "/utility/comingsoon",
    name: "comingsoon",
    meta: { title: "Comingsoon", authRequired: true },
    component: () => import("../views/pages/utility/comingsoon")
  },
  {
    path: "/auth/login-1",
    name: "login-1",
    meta: { title: "Login", authRequired: true },
    component: () => import("../views/pages/auth/login-1")
  },
  {
    path: "/auth/register-1",
    name: "register-1",
    meta: { title: "Register", authRequired: true },
    component: () => import("../views/pages/auth/register-1")
  },
  {
    path: "/auth/roles",
    name: "roles",
    meta: { title: "Roles", authRequired: true },
    component: () => import("../views/pages/auth/roles")
  },
  {
    path: "/auth/permissions",
    name: "permissions",
    meta: { title: "Permisos", authRequired: true },
    component: () => import("../views/pages/auth/permissions")
  },
  {
    path: "/auth/profiles-create",
    name: "profiles-create",
    meta: { title: "Perfiles", authRequired: true },
    component: () => import("../views/pages/auth/profiles")
  },
  {
    path: "/auth/users-list",
    name: "users-list",
    meta: { title: "AuthUsers", authRequired: true },
    component: () => import("../views/pages/auth/users-list")
  },
  {
    path: "/auth/show-profiles/",
    name: "show-profiles",
    meta: { title: "AuthUsers", authRequired: true },
    component: () => import("../views/pages/auth/show-profiles")
  },
  {
    path: "/auth/show-profile/",
    name: "show-profile",
    meta: { title: "AuthUsers", authRequired: true },
    component: () => import("../views/pages/auth/show-profile")
  },
  {
    path: "/auth/edit-users/",
    name: "edit-users",
    meta: { title: "AuthUsers", authRequired: true },
    component: () => import("../views/pages/auth/edit-users")
  },
  {
    path: "/stocktaking",
    name: "stocktaking-list",
    meta: { title: "Inventarios", authRequired: true },
    component: () => import("../views/pages/stocktaking/list")
  },
  {
    path: "/stocktaking/details",
    name: "stocktaking-details",
    meta: { title: "Detalles Inventarios", authRequired: true },
    component: () => import("../views/pages/stocktaking/details")
  },
  {
    path: "/stocktaking/create",
    name: "stocktaking-create",
    meta: { title: "Inventarios", authRequired: true },
    component: () => import("../views/pages/stocktaking/create")
  },
  {
    path: "/stocktaking/edit",
    name: "stocktaking-edit",
    meta: { title: "Editar Inventario", authRequired: true },
    component: () => import("../views/pages/stocktaking/edit")
  },
  {
    path: "/stocktaking/edit-images",
    name: "stocktaking-images",
    meta: { title: "Editar Imágenes", authRequired: true },
    component: () => import("../views/pages/stocktaking/editImage")
  },
  {
    path: "/catalogs/unitStock",
    name: "catalogs",
    meta: { title: "Catalogos", authRequired: true },
    component: () => import("../views/pages/catalogs/unitStock/list")
  },
  {
    path: "/catalogs/brands",
    name: "brands",
    meta: { title: "Marcas", authRequired: true },
    component: () => import("../views/pages/catalogs/brands/list")
  },
  {
    path: "/catalogs/suppliers",
    name: "suppliers",
    meta: { title: "Proveedores", authRequired: true },
    component: () => import("../views/pages/catalogs/suppliers/list")
  },
  {
    path: "/catalogs/models",
    name: "models",
    meta: { title: "Modelos", authRequired: true },
    component: () => import("../views/pages/catalogs/models/list")
  },
  {
    path: "/catalogs/categories",
    name: "categories",
    meta: {title: "Categorías", authRequired: true},
    component: () => import("../views/pages/catalogs/categories/category")
  },
  {
    path: "/catalogs/subcategories",
    name: "subcategories",
    meta: {title: "Subcategorías", authRequired: true},
    component: () => import("../views/pages/catalogs/categories/subcategory")
  },
  {
    path: "/catalogs/colors",
    name: "colors",
    meta: {title: "Colores", authRequired: true},
    component: () => import("../views/pages/catalogs/colors/colors")
  },
  {
    path: "/catalogs/unitWeight",
    name: "unitWeight",
    meta: {title: "Unidad Peso", authRequired: true},
    component: () => import("../views/pages/catalogs/unitWeight/list")
  },
  {
    path: "/catalogs/unitMeasure",
    name: "unitMeasure",
    meta: {title: "Unidad Medida", authRequired: true},
    component: () => import("../views/pages/catalogs/unitMeasure/list")
  },
  {
    path: "/catalogs/stores",
    name: "stores",
    meta: {title: "Almacén", authRequired: true},
    component: () => import("../views/pages/catalogs/stores/list")
  },
  {
    path: "/catalogs/wareHouse",
    name: "wareHouse",
    meta: {title: "Bodega", authRequired: true},
    component: () => import("../views/pages/catalogs/wareHouse/warehouse")
  }
];
