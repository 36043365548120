import { defineStore } from 'pinia'
import { ref,inject } from "vue";
import Auth from "@/api/Auth";
import router from "@/router";
import {useNotificationStore} from "@/stores/notification";

export const useAuthStore = defineStore('auth', () => {

    const authUser = ref(JSON.parse(localStorage.getItem('user')));
    const status = ref({
        loggedUser: false,
        user: null
    })
    const $loading = inject('$loading')
    const notification = useNotificationStore()
    function login(data) {
        const loader = $loading.show();
        Auth.login(data).then( response => {
            localStorage.setItem('user', JSON.stringify(response.data));
            loginSuccess(response.data);
            loader.hide();
            router.push('/');
        }).catch( response => {
            notification.error(response.response.data.message)
            if(response.response.status === 500 ){
                notification.error('Credenciales invalidas');
            } else {
                notification.clear()
            }
            loader.hide();
            loginFailure(response.data)
        });
    }

    function activateAccount(data) {
        const loader = $loading.show();
        Auth.activateAccount(data)
        .then( response => {
            console.log(response.data)
            localStorage.setItem('user', JSON.stringify(response.data));
            loginSuccess(response.data);
            loader.hide();
            router.push('/')
        }).catch( response => {
            response.response.status === 422 ? notification.error('Credenciales Incorrectas'): notification.clear()
            loader.hide();
        });
        return new Promise((resolve, reject) => {
            Auth.activateAccount(data)
            .then( response => {
                localStorage.setItem('user', JSON.stringify(response.data));
                loginSuccess(response.data);
                loader.hide();
                router.push('/')
            }).catch( response => {
                response.response.status === 422 ? notification.error('Credenciales Incorrectas'): notification.clear()
                loader.hide();
            });
        })
    }
    function loginSuccess(user){
        status.value.loggedUser = true
        authUser.value = user
    }
    function loginFailure() {
        status.value = null;
        authUser.value = null;
    }
    function loggedIn() {
        return !!authUser.value
    }

    function saveState(key, state) {
        window.sessionStorage.setItem(key, JSON.stringify(state))
    }

    function logout() {
        Auth.logout().then(response => {
            if(response.data.message === "Logged out") {
                localStorage.removeItem('user');
                //status.value.loggedUser = null
                //authUser.value = null
                window.location.href = "/";
            }
        })
    }

    function loguedUser() {
        return authUser.value
    }

    return {
        authUser,
        login,
        loggedIn,
        logout,
        loguedUser,
        activateAccount
    }
})