import { createApp } from "vue";
import { createPinia } from 'pinia';
import App from "./App.vue";
import router from "./router";
import acl from './acl';
import i18n from "./i18n";
import store from "@/state/store";
import { vMaska } from "maska";
import VueApexCharts from "vue3-apexcharts";
import CKEditor from "@ckeditor/ckeditor5-vue";
import BootstrapVueNext from "bootstrap-vue-next";
import "simplebar";
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
const pinia = createPinia()

import "@/assets/scss/app.scss";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

createApp(App)
    .use(pinia)
    .use(store)
    .use(router)
    .use(acl)
    .use(VueApexCharts)
    .use(BootstrapVueNext)
    .use(i18n)
    .directive("maska", vMaska)
    .use(CKEditor)
    .use(LoadingPlugin,{
        color: "#7931B4"
    })
    .mount("#app");
